import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="timesheet"
export default class extends Controller {
  static targets = [
    "planningFields", "absenceFields", "clockingFields", "finalFields",
    "restaurantSelect", "employeeSelect", "hourlyCost"
  ]

  connect() {
    const statusRecSelect = this.element.querySelector('#employee_time_sheet_status_rec');
    if (statusRecSelect && statusRecSelect.value) {
      // Appeler toggleFields avec la valeur actuelle de status_rec
      this.toggleFields({ target: { value: statusRecSelect.value } });
    }

    if (this.employeeSelectTarget && this.employeeSelectTarget.value) {
      this.updateHourlyCost({ target: this.employeeSelectTarget });
    }

    if (this.restaurantSelectTarget && this.restaurantSelectTarget.value) {
      this.employeeSelectTarget.disabled = false;
    }
  }

  hasAllRequiredFields() {
    // Vérifie que tous les champs requis pour le reset existent
    return this.element.querySelector('#employee_time_sheet_planning_time') &&
           this.element.querySelector('#employee_time_sheet_planning_cost') &&
           this.element.querySelector('#employee_time_sheet_absence_time') &&
           this.element.querySelector('#employee_time_sheet_absence_cost') &&
           this.element.querySelector('#employee_time_sheet_clocking_time') &&
           this.element.querySelector('#employee_time_sheet_clocking_cost') &&
           this.element.querySelector('#employee_time_sheet_final_time') &&
           this.element.querySelector('#employee_time_sheet_final_cost')
  }

  async loadEmployees(event) {
    const restaurantId = event.target.value
    if (!restaurantId) {
      this.employeeSelectTarget.disabled = true
      this.employeeSelectTarget.innerHTML = '<option value="">Select restaurant first</option>'
      return
    }

    let url = `/employee_time_sheets/employees_for_restaurant?restaurant_id=${restaurantId}`
    if (document.body.dataset.locale) {
        url = `/${document.body.dataset.locale}${url}`
    }

    try {
      const response = await fetch(url)
      const employees = await response.json()

      this.employeeSelectTarget.innerHTML = `
        <option value="">Select an employee</option>
        ${employees.map(e => `<option value="${e.id}">${e.name}</option>`).join('')}
      `
      this.employeeSelectTarget.disabled = false
    } catch (error) {
      console.error('Failed to load employees:', error)
    }
  }

  async updateHourlyCost(event) {
    const employeeId = event.target.value;

    if (!employeeId) {
      // Aucun employé sélectionné, remettre à 0
      this.hourlyCostTarget.textContent = "0";
      return;
    }

    try {
      // Effectuer une requête pour obtenir le coût horaire
      const response = await fetch(`/employees/${employeeId}/hourly_cost`);
      const data = await response.json();

      // Mettre à jour la valeur affichée
      this.hourlyCostTarget.textContent = data.hourly_cost || "0";
    } catch (error) {
      console.error("Erreur lors de la récupération du coût horaire :", error);
      this.hourlyCostTarget.textContent = "Erreur";
    }
  }

  updateCost(event) {
    console.log('updateCost')
    const input = event.target;
    const hourlyCost = parseFloat(this.hourlyCostTarget.textContent);

    if (isNaN(hourlyCost) || hourlyCost <= 0) {
      this.showBlockingWarning("Le coût horaire est de 0 €, veuillez sélectionner un employé valide.");
      return;
    }

    const time = parseFloat(input.value);
    if (isNaN(time)) return;

    const correspondingCostField = this.getCostFieldForTimeField(input);
    if (correspondingCostField) {
      correspondingCostField.value = (hourlyCost * time).toFixed(0);
    }
  }

  toggleFields(event) {
    this.hideAllFields();

    const type = event.target.value;
    switch (type) {
      case 'planning':
        this.planningFieldsTargets.forEach(el => {
          el.classList.remove('d-none');
          el.querySelectorAll('input').forEach(input => input.required = true);
        });
        break;
      case 'absence':
        this.absenceFieldsTargets.forEach(el => {
          el.classList.remove('d-none');
          el.querySelectorAll('input, select').forEach(input => input.required = true);
        });
        break;
      case 'clocking':
        this.clockingFieldsTargets.forEach(el => {
          el.classList.remove('d-none');
          el.querySelectorAll('input').forEach(input => input.required = true);
        });
        break;
      case 'final':
        this.finalFieldsTargets.forEach(el => {
          el.classList.remove('d-none');
          el.querySelectorAll('input').forEach(input => input.required = true);
        });
        break;
    }
  }

  getCostFieldForTimeField(timeField) {
    const fieldMap = {
      "employee_time_sheet_planning_time": "employee_time_sheet_planning_cost",
      "employee_time_sheet_absence_time": "employee_time_sheet_absence_cost",
      "employee_time_sheet_clocking_time": "employee_time_sheet_clocking_cost",
      "employee_time_sheet_final_time": "employee_time_sheet_final_cost"
    };

    const costFieldId = fieldMap[timeField.id];
    return costFieldId ? this.element.querySelector(`#${costFieldId}`) : null;
  }

  showBlockingWarning(message) {
    alert(message);
    throw new Error(message); // Empêche toute action si le coût horaire est 0
  }

  setAbsenceTypeRequired(required) {
    const absenceTypeSelect = this.element.querySelector('#employee_time_sheet_absence_type')
    if (absenceTypeSelect) {
      absenceTypeSelect.required = required
    }
  }

  hideAllFields() {
    const hideAndRemoveRequired = (elements) => {
      elements.forEach(el => {
        el.classList.add('d-none');
        el.querySelectorAll('input, select').forEach(input => input.required = false);
      });
    };

    hideAndRemoveRequired(this.planningFieldsTargets);
    hideAndRemoveRequired(this.absenceFieldsTargets);
    hideAndRemoveRequired(this.clockingFieldsTargets);
    hideAndRemoveRequired(this.finalFieldsTargets);
  }

  resetHiddenFields(selectedType) {
    const form = this.element
    if (!this.hasAllRequiredFields()) return
    if (selectedType !== 'planning') {
      form.querySelector('#employee_time_sheet_planning_time').value = '0'
      form.querySelector('#employee_time_sheet_planning_cost').value = '0'
    }
    if (selectedType !== 'absence') {
      form.querySelector('#employee_time_sheet_absence_time').value = '0'
      form.querySelector('#employee_time_sheet_absence_cost').value = '0'
    }
    if (selectedType !== 'clocking') {
      form.querySelector('#employee_time_sheet_clocking_time').value = '0'
      form.querySelector('#employee_time_sheet_clocking_cost').value = '0'
    }
    if (selectedType !== 'final') {
      form.querySelector('#employee_time_sheet_final_time').value = '0'
      form.querySelector('#employee_time_sheet_final_cost').value = '0'
    }
  }
}
