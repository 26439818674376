import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    maxProducts: Number
  }

  connect() {
    this.alertShowing = false

    requestAnimationFrame(() => {
      // On met à jour l'état initial
      this.updateSelectedCount()
      this.bindEvents()
    })
  }

  bindEvents() {
    document.querySelectorAll('.primary-category').forEach(checkbox => {
      checkbox.addEventListener('change', (e) => this.handlePrimaryChange(e))
    })

    document.querySelectorAll('.secondary-category').forEach(checkbox => {
      checkbox.addEventListener('change', (e) => this.handleSecondaryChange(e))
    })
  }

  handlePrimaryChange(event) {
    const primaryCheckbox = event.target
    const categoryGroup = primaryCheckbox.closest('.category-group')

    if (primaryCheckbox.checked) {
      // On ne permet pas de sélectionner une catégorie principale si elle dépasse la limite
      const wouldExceedLimit = this.wouldExceedLimit(primaryCheckbox)

      if (wouldExceedLimit) {
        event.preventDefault()
        primaryCheckbox.checked = false
        this.showLimitAlert()
        return
      }
    }

    categoryGroup.querySelectorAll('.secondary-category').forEach(checkbox => {
      checkbox.checked = primaryCheckbox.checked
    })

    this.updateSelectedCount()
  }

  handleSecondaryChange(event) {
    const secondaryCheckbox = event.target
    const productCount = parseInt(secondaryCheckbox.dataset.productCount) || 0

    if (secondaryCheckbox.checked) {
      // Vérification spéciale pour les grosses catégories
      if (productCount > this.maxProductsValue) {
        // On vérifie s'il y a d'autres éléments sélectionnés
        const otherChecked = Array.from(document.querySelectorAll('.primary-category:checked, .secondary-category:checked'))
          .filter(cb => cb !== secondaryCheckbox)
          .length > 0

        if (otherChecked) {
          // S'il y a d'autres éléments sélectionnés, on empêche la sélection
          event.preventDefault()
          secondaryCheckbox.checked = false
          this.showLimitAlert("Cette catégorie ne peut être sélectionnée qu'individuellement car elle dépasse la limite")
          return
        }

        // Si c'est le seul élément, on désactive tous les autres
        this.disableAllExcept(secondaryCheckbox)
      } else {
        // Comportement normal pour les petites catégories
        const wouldExceedLimit = this.wouldExceedLimit(secondaryCheckbox)
        if (wouldExceedLimit) {
          event.preventDefault()
          secondaryCheckbox.checked = false
          this.showLimitAlert()
          return
        }
      }
    } else {
      // Si on décoche une grosse catégorie, on réactive tout
      if (productCount > this.maxProductsValue) {
        this.enableAllCheckboxes()
      }
    }

    this.updateParentCheckbox(secondaryCheckbox)
    this.updateSelectedCount()
  }

  uncheckAllExcept(exceptCheckbox) {
    document.querySelectorAll('.primary-category, .secondary-category').forEach(checkbox => {
      if (checkbox !== exceptCheckbox) {
        checkbox.checked = false
      }
    })
  }

  disableAllExcept(exceptCheckbox) {
    document.querySelectorAll('.primary-category, .secondary-category').forEach(checkbox => {
      if (checkbox !== exceptCheckbox) {
        checkbox.disabled = true
      }
    })
  }

  enableAllCheckboxes() {
    document.querySelectorAll('.primary-category, .secondary-category').forEach(checkbox => {
      checkbox.disabled = false
    })
    this.updateCheckboxesState(this.calculateTotalProducts())
  }

  updateParentCheckbox(secondaryCheckbox) {
    const categoryGroup = secondaryCheckbox.closest('.category-group')
    const primaryCheckbox = categoryGroup.querySelector('.primary-category')
    const allSecondaries = categoryGroup.querySelectorAll('.secondary-category')
    const allChecked = Array.from(allSecondaries).every(cb => cb.checked)

    if (primaryCheckbox) {
      primaryCheckbox.checked = allChecked
    }
  }

  updateSelectedCount() {
    const countInfo = document.querySelector('.product-count-info')
    const totalCount = this.calculateTotalProducts()
    // Retrait de la vérification hasLargeCategory ici
    const isOverLimit = totalCount > this.maxProductsValue

    countInfo.style.display = 'block'
    const countSpan = countInfo.querySelector('.selected-count')
    countSpan.textContent = totalCount

    this.updateRefreshButtonState(totalCount, isOverLimit)
    this.updateCheckboxesState(totalCount)
  }

  hasSelectedLargeCategory() {
    let hasLarge = false
    document.querySelectorAll('.secondary-category:checked').forEach(checkbox => {
      const productCount = parseInt(checkbox.dataset.productCount) || 0
      if (productCount > this.maxProductsValue) {
        hasLarge = true
      }
    })
    return hasLarge
  }

  updateRefreshButtonState(count, isOverLimit) {
    const refreshButton = document.getElementById('refresh-button')

    // On vérifie si on a une grosse catégorie unique sélectionnée
    const hasLargeCategory = this.hasSelectedLargeCategory()
    const onlyOneCategorySelected = document.querySelectorAll('.primary-category:checked, .secondary-category:checked').length === 1

    // Le bouton est désactivé si:
    // - count est 0 OU
    // - on dépasse la limite SAUF si c'est une grosse catégorie unique
    const shouldDisable = count === 0 || (isOverLimit && !(hasLargeCategory && onlyOneCategorySelected))

    refreshButton.disabled = shouldDisable

    if (shouldDisable) {
      refreshButton.classList.add('btn-secondary')
      refreshButton.classList.remove('btn-primary')

      let message
      if (count === 0) {
        message = 'Veuillez sélectionner au moins une catégorie'
      } else if (isOverLimit) {
        message = `Veuillez réduire la sélection à ${this.maxProductsValue} produits maximum`
      }

      refreshButton.setAttribute('title', message)
      refreshButton.setAttribute('aria-label', message)
      refreshButton.innerHTML = `<i class="fa-solid fa-arrows-rotate"></i> ${message}`
    } else {
      refreshButton.classList.add('btn-primary')
      refreshButton.classList.remove('btn-secondary')
      refreshButton.removeAttribute('title')
      refreshButton.removeAttribute('aria-label')
      refreshButton.innerHTML = `<i class="fa-solid fa-arrows-rotate"></i> Refresh`
    }
  }

  updateCheckboxesState(totalCount) {
    // Si on est à 0, tout est réactivé, mais on gère différemment les grosses catégories
    if (totalCount === 0) {
      // Réactiver toutes les cases normales
      document.querySelectorAll('.primary-category, .secondary-category').forEach(checkbox => {
        const productIds = checkbox.dataset.productIds?.split(',') || []

        // Si c'est une catégorie principale qui dépasse la limite, on la désactive
        if (checkbox.classList.contains('primary-category') && productIds.length > this.maxProductsValue) {
          checkbox.disabled = true
        } else {
          checkbox.disabled = false
        }
      })
      return
    }

    // Les cases cochées sont toujours actives pour permettre le décochage
    document.querySelectorAll('.primary-category:checked, .secondary-category:checked').forEach(checkbox => {
      checkbox.disabled = false
    })

    // Pour chaque case non cochée, vérifier si son ajout dépasserait la limite
    document.querySelectorAll('.primary-category:not(:checked), .secondary-category:not(:checked)').forEach(checkbox => {
      const productIds = checkbox.dataset.productIds?.split(',') || []
      const currentProductCount = totalCount
      const wouldAdd = productIds.length

      // Si c'est une sous-catégorie qui dépasse individuellement la limite,
      // on vérifie s'il y a d'autres cases cochées
      if (wouldAdd > this.maxProductsValue && checkbox.classList.contains('secondary-category')) {
        const otherChecked = Array.from(document.querySelectorAll('.primary-category:checked, .secondary-category:checked')).length > 0
        checkbox.disabled = otherChecked
        return
      }

      // Pour les cas normaux, on vérifie si l'ajout dépasserait la limite
      checkbox.disabled = (currentProductCount + wouldAdd) > this.maxProductsValue
    })

    // Mettre à jour le style de l'alerte
    const countInfo = document.querySelector('.product-count-info')
    if (totalCount > this.maxProductsValue) {
      countInfo.classList.add('alert-danger')
      countInfo.classList.remove('alert-info')
    } else {
      countInfo.classList.add('alert-info')
      countInfo.classList.remove('alert-danger')
    }
  }

  showLimitAlert() {
    if (this.alertShowing) return

    this.alertShowing = true
    const alertDiv = document.createElement('div')
    alertDiv.className = 'alert alert-warning alert-dismissible fade show'
    alertDiv.innerHTML = `
      La limite de ${this.maxProductsValue} produits a été atteinte
      <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
    `
    this.element.insertBefore(alertDiv, this.element.firstChild)

    setTimeout(() => {
      alertDiv.remove()
      this.alertShowing = false
    }, 3000)
  }

  wouldExceedLimit(newCheckbox) {
    const selectedProducts = new Set()

    document.querySelectorAll('.primary-category:checked, .secondary-category:checked').forEach(checkbox => {
      if (checkbox !== newCheckbox) {
        const productIds = checkbox.dataset.productIds?.split(',') || []
        productIds.forEach(id => selectedProducts.add(id))
      }
    })

    const newProductIds = newCheckbox.dataset.productIds?.split(',') || []
    newProductIds.forEach(id => selectedProducts.add(id))

    return selectedProducts.size > this.maxProductsValue
  }

  calculateTotalProducts() {
    const selectedProducts = new Set()

    document.querySelectorAll('.primary-category:checked, .secondary-category:checked').forEach(checkbox => {
      const productIds = checkbox.dataset.productIds?.split(',') || []
      productIds.forEach(id => selectedProducts.add(id))
    })

    return selectedProducts.size
  }
}
