import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['status', 'content', 'progress']
    static values = {
        maxAttempts: { type: Number, default: 60 },
        minDisplayTime: { type: Number, default: 2000 },
    }

    connect() {
        if (
            this.hasStatusTarget &&
            this.contentTarget.dataset.status === 'processing'
        ) {
            this.showLoadingModal()
            this.startPolling()
        }
    }

    showLoadingModal() {
        const modal = new bootstrap.Modal(
            document.getElementById('loadingModalProgressReport')
        )
        modal.show()

        const durationElement = document.getElementById(
            'estimated-duration-value'
        )
        if (durationElement) {
            const duration = Math.ceil(
                this.contentTarget.dataset.estimatedDuration / 1000
            )
            durationElement.textContent = `${duration} ${duration > 1 ? 'secondes' : 'seconde'}`
        }
    }

    startPolling() {
        this.attempts = 0
        this.startTime = Date.now()
        this.checkStatus()
    }

    async checkStatus() {
        if (this.attempts >= this.maxAttemptsValue) {
            this.handleError('Maximum polling attempts reached')
            return
        }

        try {
            const response = await fetch(window.location.href, {
                headers: { Accept: 'application/json' },
            })

            if (!response.ok)
                throw new Error(`HTTP error! status: ${response.status}`)

            const data = await response.json()

            // Si le statut n'est plus 'processing', on arrête le polling
            if (!data.processing) {
                this.completeProcess(data)
                return
            }

            this.attempts++
            this.updateProgress()
            // Continue le polling
            this.pollTimeout = setTimeout(() => this.checkStatus(), 1000)
        } catch (error) {
            console.error('Polling error:', error)
            this.handleError(error)
        }
    }

    disconnect() {
        // Nettoyer le timeout lors de la déconnexion du contrôleur
        if (this.pollTimeout) {
            clearTimeout(this.pollTimeout)
        }
    }

    updateProgress() {
        if (this.hasProgressTarget) {
            const progress = (this.attempts / this.maxAttemptsValue) * 100
            this.progressTarget.style.width = `${progress}%`
            this.progressTarget.setAttribute('aria-valuenow', progress)
        }
    }

    completeProcess(data) {
        const modal = bootstrap.Modal.getInstance(
            document.getElementById('loadingModalProgressReport')
        )
        if (modal) modal.hide()
        if (data.data) {
            // On garde l'URL courante avec ses paramètres
            const currentURL = window.location.href

            // On force le navigateur à vraiment recharger la page depuis le serveur
            window.location.href = currentURL

            // Alternativement, si tu veux être sûr que les paramètres sont bien pris en compte :
            // const url = new URL(window.location.href)
            // window.location.replace(url.href)
        }
    }

    handleError(error) {
        console.error('Error:', error)
        const modal = bootstrap.Modal.getInstance(
            document.getElementById('loadingModalProgressReport')
        )
        if (modal) modal.hide()
        alert('Une erreur est survenue lors du chargement des données')
    }
}
