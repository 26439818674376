import { Controller } from '@hotwired/stimulus'
import { I18n } from 'i18n-js'
import translations from '../translations.json'

const i18n = new I18n(translations)
const userLocale = document
    .querySelector("meta[name='user-locale']")
    .getAttribute('content')
i18n.locale = userLocale

// Connects to data-controller="chart-dashboard-pie-absence"
export default class extends Controller {
    static targets = ['time', 'dateRange']

    connect() {
        this.setDefaultTime()
        if (typeof Highcharts === 'undefined') {
            document.addEventListener('DOMContentLoaded', () => {
                setTimeout(() => this.loadChart(), 100)
            })
        } else {
            this.loadChart()
        }
    }

    setDefaultTime() {
        const selectElement = this.element.querySelector('select')
        if (selectElement && selectElement.options.length > 0) {
            selectElement.options[0].selected = true
        }
    }

    updateChart() {
        const selectedPeriod = this.timeTarget.value
        this.chart.showLoading()
        let url = `/dashboard/call_pie_absence_cost_service?period=${selectedPeriod}&format=json`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        fetch(url, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
                return response.json()
            })
            .then((response) => {
                // seriesData = response.data;
                if (response.period_dates) {
                    this.dateRangeTarget.textContent = `${i18n.t('i18njs.Period')}: ${response.period_dates.start_date} - ${response.period_dates.end_date}`
                }
                // Mise à jour de la série avec les nouvelles données
                if (this.chart.series.length > 0) {
                    this.chart.series[0].setData(
                        response.data.map((item) => ({
                            name: item.name,
                            y: item.y,
                            drilldown: item.drilldown,
                            data: item.data,
                        }))
                    )
                } else {
                    this.chart.addSeries({
                        name: 'Absence Costs',
                        colorByPoint: true,
                        data: response.data,
                    })
                }
                this.chart.hideLoading()
            })
            .catch((error) => {
                console.error('Error updating chart:', error)
            })
    }

    loadChart() {
        if (typeof Highcharts === 'undefined') {
            console.error('Highcharts not loaded')
            return
        }
        const chartData = this.element.querySelector(
            '#chart-data-dashboard-absence'
        )
        const seriesData = JSON.parse(
            chartData.getAttribute('data-series-absence')
        )

        const initialData = JSON.parse(
            chartData.getAttribute('data-initial-period')
        )
        if (initialData?.period_dates) {
            this.dateRangeTarget.textContent = `${i18n.t('i18njs.Period')}: ${initialData.period_dates.start_date} - ${initialData.period_dates.end_date}`
        }

        this.chart = Highcharts.chart('chart-data-dashboard-absence', {
            chart: {
                type: 'pie',
            },
            title: {
                text: null,
            },
            tooltip: {
                useHTML: true,
                headerFormat:
                    '<span style="font-size:11px">{series.name}</span><br>',
                pointFormatter: function () {
                    const totalCost = Highcharts.numberFormat(
                        this.y,
                        0,
                        '.',
                        ','
                    )
                    let tooltipHtml = `<span style="color:${this.color}">${this.name}</span>: <b>${totalCost}€</b> Total<br/><table class="table table-striped table-bordered table-hover table-sm">`
                    tooltipHtml += `<thead><tr><th>Nom</th><th>€</th><th>%</th><th>Heures</th><th>Bradford</th></tr></thead><tbody>`

                    this.data.forEach((employee) => {
                        const employeeCost = Highcharts.numberFormat(
                            employee.y,
                            2,
                            ',',
                            '.'
                        )
                        const employeeHours = Math.round(employee.hours)
                        const employeePercentage = employee.percentage
                        const employeeBradford = employee.bradford
                        tooltipHtml += `<tr><td>${employee.name}</td><td>${employeeCost}€</td><td>${employeePercentage}%</td><td>${employeeHours}</td><td>${employeeBradford}</td></tr>`
                    })

                    tooltipHtml += `</tbody></table>`
                    return tooltipHtml
                },
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            return `${this.point.name}: ${Highcharts.numberFormat(this.y, 0, ',', '.')}€`
                        },
                    },
                },
            },
            series: [
                {
                    name: 'Absence Costs',
                    colorByPoint: true,
                    data: seriesData.map((item) => ({
                        name: item.name,
                        y: item.y,
                        drilldown: item.drilldown,
                        data: item.data,
                    })),
                },
            ],
            drilldown: {
                series: seriesData,
            },
            credits: {
                enabled: false,
            },
        })
    }

    // Répétez pour 'container_last_month' avec les données du mois passé.
}
