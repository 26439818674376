import { Controller } from '@hotwired/stimulus'
import { I18n } from 'i18n-js'
import translations from '../translations.json'

const i18n = new I18n(translations)

const userLocale = document
    .querySelector("meta[name='user-locale']")
    .getAttribute('content')
i18n.locale = userLocale

// Connects to data-controller="chart-ingredient"
export default class extends Controller {
    connect() {
        if (typeof Highcharts === 'undefined') {
            document.addEventListener('DOMContentLoaded', () => {
                setTimeout(() => this.loadChart(), 100)
            })
        } else {
            this.loadChart()
        }
    }

    loadChart() {
        if (typeof Highcharts === 'undefined') {
            console.error('Highcharts not loaded')
            return
        }
        const chartData = this.element.querySelector('#chart-data')
        const categoriesData = JSON.parse(
            chartData.getAttribute('data-categories')
        )
        const seriesData = JSON.parse(chartData.getAttribute('data-series'))

        Highcharts.chart('chart-data', {
            title: {
                useHTML: true,
                text: `<i class="fa-solid fa-chart-line fa-2xl text-primary"></i> ${i18n.t('i18njs.increases_graph')}`,
            },
            xAxis: {
                categories: categoriesData,
            },
            yAxis: {
                tickInterval: 100,
                labels: {
                    formatter: function () {
                        return (this.value / 100).toFixed(0)
                    },
                },
            },
            series: [
                {
                    name: i18n.t('Price per date'),
                    data: seriesData,
                },
            ],
            tooltip: {
                pointFormatter: function () {
                    return (
                        `${i18n.t('i18njs.price')}: ` +
                        parseFloat(this.y / 100).toFixed(2) +
                        ' €'
                    )
                },
            },
            credits: {
                enabled: false,
            },
        })
    }
}
